import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Modal from './Modal';
import FinalClient from './FinalClient';


const MainFinalClient = () => {
    const { deliveryId } = useParams();
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [deliveryPoint, setDeliveryPoint] = useState(null);
    const [deliveryMarker, setDeliveryMarker] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const geocoderRef = useRef(null);
    const autocompleteServiceRef = useRef(null);
    const currentMarkerRef = useRef(null);
    
    
    useEffect(() => {
        // Load Google Maps script
        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = () => initMap();
                document.head.appendChild(script);
            } else {
                initMap();
            }
        };

        const initMap = () => {
            if (mapContainerRef.current) {
                const mapInstance = new window.google.maps.Map(mapContainerRef.current, {
                    center: { lat: -12.060235303763926, lng: -77.03711992646711 },
                    zoom: 12,
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                });

                mapRef.current = mapInstance;

                // Initialize geocoder and autocomplete service
                geocoderRef.current = new window.google.maps.Geocoder();
                autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();

                if (deliveryPoint && deliveryPoint.latitude && deliveryPoint.longitude) {
                    const deliveryLatLng = new window.google.maps.LatLng(deliveryPoint.latitude, deliveryPoint.longitude);
                    const deliveryMarkerInstance = new window.google.maps.Marker({
                        position: deliveryLatLng,
                        map: mapInstance,
                        icon: {
                            url: 'https://maps.google.com/mapfiles/ms/icons/homegardenbusiness.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        },
                    });
                    setDeliveryMarker(deliveryMarkerInstance);
                    mapInstance.setCenter(deliveryLatLng);
                    mapInstance.setZoom(15);
                }

                // Add click listener to place marker
                mapInstance.addListener('click', (e) => {
                    const location = {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                    };
                    setSelectedLocation(location);
                    addMarker(location);
                });
            }
        };

        loadGoogleMapsScript();
    }, [deliveryPoint]);


    useEffect(() => {
        // Fetch delivery data
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery/${deliveryId}`)
            .then(response => {
                setDeliveryPoint(response.data);
            })
            .catch(error => console.error('Error fetching delivery data:', error));
    }, [deliveryId]);

    useEffect(() => {
        // Fetch predictions when searchQuery changes
        if (!searchQuery.trim()) {
            setSuggestions([]);
            return;
        }

        if (!autocompleteServiceRef.current) return; // Wait until map and services are initialized

        autocompleteServiceRef.current.getPlacePredictions({
            input: searchQuery,
            componentRestrictions: { country: 'pe' },
            location: new window.google.maps.LatLng(-12.0464, -77.0428),
            radius: 50000,
        }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                setSuggestions(predictions);
            } else {
                setSuggestions([]);
            }
        });
    }, [searchQuery]);

    const handleSuggestionClick = (prediction) => {
        if (!geocoderRef.current) return;
        geocoderRef.current.geocode({ placeId: prediction.place_id }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                const location = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                };
                setSelectedLocation(location);
                addMarker(location);
                mapRef.current.panTo(location);
                mapRef.current.setZoom(17);
            } else {
                console.error('No geometry found for this place.');
            }
        });
        setSearchQuery(prediction.description);
        setSuggestions([]);
    };

    

    useEffect(() => {
        // Show modal immediately after the page loads
        setShowModal(true);
        // Show modal every 45 seconds
        const intervalId = setInterval(() => {
            setShowModal(true);
        }, 45000);

        return () => clearInterval(intervalId);
    }, []);

    const addMarker = ({ lat, lng }) => {
        if (currentMarkerRef.current) {
            currentMarkerRef.current.setMap(null);
        }

        currentMarkerRef.current = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapRef.current,
            icon: {
                url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                scaledSize: new window.google.maps.Size(50, 50),
                labelOrigin: new window.google.maps.Point(25, -10), // Ensure the label appears correctly
            },
            label: {
                text: 'Entrega aquí',
                color: '#000',
                fontWeight: 'bold',
                fontSize: '14px',
            },
            animation: window.google.maps.Animation.BOUNCE, // Animation to make the marker more prominent
        });
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const location = { lat: latitude, lng: longitude };
                    setSelectedLocation(location);
                    addMarker(location);
                    mapRef.current.panTo(location);
                    mapRef.current.setZoom(16);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleSave = async () => {
        if (selectedLocation) {
            const isConfirmed = window.confirm("¿Está seguro que la ubicación marcada es el punto exacto de entrega?");
            if (isConfirmed) {
                try {
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/delivery/${deliveryId}/location`, {
                        latitude: selectedLocation.lat,
                        longitude: selectedLocation.lng,
                    });
                    alert('Ubicación guardada exitosamente!');
                    window.location.reload();
                } catch (error) {
                    console.error('Error saving location:', error);
                }
            }
        }
    };

    if (!deliveryPoint) {
        return <div>Loading...</div>;
    }

    // Render FinalClient component if delivery location is available
    if (deliveryPoint.latitude && deliveryPoint.longitude) {
        return <FinalClient deliveryPoint={deliveryPoint} />;
    }

    return (
        <div style={{ height: "100vh", position: "relative" }}>
            <div ref={mapContainerRef} style={{ height: "100vh" }}></div>
            <div className="search-container" style={{ position: "absolute", top: "40px", left: "10px", zIndex: 1000, width: "80%" }}>
                <input
                    id="search-input"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Ingresa la dirección EXACTA de entrega"
                    className="search-input"
                    style={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "16px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                    }}
                />
                {suggestions.length > 0 && (
                    <div style={{
                        background: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginTop: '2px',
                        maxHeight: '150px',
                        overflowY: 'auto',
                        position: 'relative',
                        zIndex: 2000,
                        color: 'black',
                    }}>
                        {suggestions.map(suggestion => (
                            <div
                                key={suggestion.place_id}
                                onClick={() => handleSuggestionClick(suggestion)}
                                style={{
                                    padding: '8px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #eee'
                                }}
                            >
                                {suggestion.description}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <IconButton
                style={{
                    position: "absolute",
                    top: "40px",
                    right: 20,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "1px solid black",
                    borderRadius: "50%",
                    color: "black",
                }}
                onClick={handleGetCurrentLocation}
            >
                <MyLocationIcon />
            </IconButton>
            {!deliveryPoint.latitude && !deliveryPoint.longitude && (
                <button
                  onClick={handleSave}
                  style={{
                      position: "absolute",
                      bottom: "120px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      padding: "10px 10px",
                      backgroundColor: "#ff5e5e",
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "15px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
              >
                  Guardar ubicación de entrega
              </button>
            )}
            {showModal && (
                <Modal 
                    message={<div>Use la barra de búsqueda, el icono <MyLocationIcon style={{ verticalAlign: 'middle' }} /> para obtener su ubicación actual, o toque el mapa para colocar el marcador. 
                Una vez colocado el marcador <img src='https://maps.google.com/mapfiles/ms/icons/blue-dot.png' alt='Marcador' style={{ width: '20px', verticalAlign: 'middle' }} />, asegúrese de que esté en la ubicación correcta y presione 'Guardar ubicación de entrega'.</div>} onClose={() => setShowModal(false)}
                />
            )}
            {

            }
        </div>
    );
};

export default MainFinalClient;
