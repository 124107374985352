import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Card, CardContent, IconButton, Typography, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { districts } from '../user/Districs'; // Import the list of districts
import axios from 'axios';

const AddEntrega = ({ user_id, onClose }) => {
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [name, setName] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [district, setDistrict] = useState('');  // Default district state
    const [cobrar, setCobrar] = useState(0);
    const [driverId, setDriverId] = useState(null);

    const driver_1 = ["Barranco", "Chorrillos", "Miraflores", "San Borja", "San Luis", "Santiago de Surco", "Surquillo", "San Juan de Miraflores", "Villa El Salvador", "Villa Maria del Triunfo", "Villa el Salvador"];   
    const driver_3 = ["Ate", "Rímac", "Santa Anita", "La Molina", "El Agustino", "San Juan de Lurigancho"];
    const driver_2 = ["Comas", "Independencia", "Carabayllo"];
    const driver_4 = ["Callao", "Jesús María", "Bellavista", "Lince", "Magdalena del Mar", "Carmen de la Legua", "La Perla", "La Punta", "Cercado de Lima", "San Miguel", "San Isidro", "Pueblo Libre", "La Victoria", "Breña"];
    const driver_5 = ["Puente Piedra", "Ventanilla", "Ancon", "Santa Rosa", "San Martín de Porres", "Los Olivos"];

    useEffect(() => {
        if (district) {
            if (driver_1.includes(district)) {
                setDriverId(7);
            } else if (driver_2.includes(district)) {
                setDriverId(3);
            } else if (driver_3.includes(district)) {
                setDriverId(6);
            } else if (driver_4.includes(district)) {
                setDriverId(54);
            } else if (driver_5.includes(district)) {
                setDriverId(183);
            } else {
                setDriverId(null); // No matching driver
            }
        }
    }, [district]);
    // Handle save logic to call the API
    const handleSave = async () => {
        try {
            const formattedData = {
                user_id,  // user_id passed as a prop
                delivery_date: date.toISOString(),  // Format the date to ISO 8601
                recipient_name: name,
                recipient_phone: telefono,
                address: direccion,
                district: district,
                driver_id: driverId,
                additional_notes: notes,
                charge: cobrar,
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/entrega`, formattedData);
            console.log('Data saved successfully:', formattedData);
            onClose();  // Close the edit card after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Card sx={{ maxWidth: 300, position: 'absolute', top: "80px", left: "820px", maxHeight: "450px", overflowY: 'auto', }}>
            <CardContent>
                <Typography variant="h6">Agregar Entrega</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Recipient Name */}
                    <TextField
                        label="Nombre del destinatario"
                        value={name}
                        onChange={(e) => setName(e.target.value)}  // Update name state
                    />
                    {/* Recipient Phone */}
                    <TextField
                        label="Teléfono del destinatario"
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}  // Update telefono state
                    />
                    {/* Address */}
                    <TextField
                        label="Dirección"
                        value={direccion}
                        onChange={(e) => setDireccion(e.target.value)}  // Update direccion state
                    />
                    {/* District - Dropdown for selecting district */}
                    <Select
                        label="Distrito"
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}  // Update district state
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 400, // Adjust as necessary for your layout
                                    overflow: 'auto',
                                    position: 'absolute',
                                    left: '50%', // Position it next to the EditionCard
                                    top: '80px',
                                    zIndex: 2500,
                                    paddingLeft: '40px',
                                },
                            },
                        }}
                    >
                        <MenuItem value="" disabled>
                            Selecciona un distrito
                        </MenuItem>
                        {Object.keys(districts).map((districtName) => (
                            <MenuItem key={districtName} value={districtName}>
                                {districtName}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* Charge */}
                    <TextField
                        label="Cobrar"
                        type="number"
                        value={cobrar}
                        onChange={(e) => setCobrar(Number(e.target.value))}  // Update cobrar state
                    />
                    {/* Notes */}
                    <TextField
                        label="Notas adicionales"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}  // Update notes state
                    />
                    {/* Delivery Date */}
                    <TextField
                        label="Fecha de Entrega"
                        type="date"
                        value={date.toISOString().split('T')[0]}  // Display only the datetime part
                        onChange={(e) => setDate(new Date(e.target.value))}  // Update date state
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Guardar
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AddEntrega;
